// ---------------------------------------------
// MIXINS AND FUNCTIONS - TEXT
// ---------------------------------------------

// -------------------------------
// MIXINS

@mixin font-size($font-size, $font-size-r: $font-size-default) {
  $font-size: round($font-size);

  font-size: $font-size;
  font-size: ($font-size / $font-size-default) * 1rem;
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
