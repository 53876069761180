$color-palette: (
  "primary": (
    500: #ff5722,
  ),
  "secondary": (
    500: #21a2ff,
  ),
  "error": (
    500: #fa5252,
    50: #fff5f5,
    100: #ffe3e3,
    300: #ff6b6b,
  ),
  "success": (
    500: #3adb76,
  ),
  "grey": (
    500: #adb5bd,
  ),
);
