// ---------------------------------------------
// TYPOGRAPHY UTILITIES
// ---------------------------------------------

.text-transform-upper {
  text-transform: uppercase;
}
.text-transform-capitalize {
  text-transform: capitalize;
}
.text-transform-none {
  text-transform: none;
}

strong,
.text-bold {
  font-weight: 700;
}
.text-semibold {
  font-weight: 600;
}
.text-light {
  font-weight: 300;
}
.text-normal {
  font-weight: normal;
}

em,
.text-italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.text-vam {
  vertical-align: middle;
}
.text-vat {
  vertical-align: top;
}
.text-vab {
  vertical-align: bottom;
}
.text-vabl {
  vertical-align: baseline;
}

.text-space-half {
  margin-bottom: _get-space("small");
}
.text-nospace {
  margin: 0;
}
.text-space-left {
  margin-left: _get-space("small");
}
.text-space-right {
  margin-right: _get-space("small");
}

.text-nowrap {
  white-space: nowrap;
}
.text-ellipsis {
  @include text-ellipsis;
}

.text-color-primary {
  color: $text-color-primary;
}
.text-color-secondary {
  color: $text-color-secondary;
}
.text-color-black {
  color: $text-color-black;
}
.text-color-grey {
  color: $text-color-grey;
}
.text-color-white {
  color: $text-color-white;
}
.text-color-success {
  color: $text-color-success;
}
.text-color-error {
  color: $text-color-error;
}
