// ---------------------------------------------
// TYPOGRAPHY - MIXINS
// ---------------------------------------------

@import "./config";

@mixin headings() {
  @include get-variants-responsive-deep($heading-settings);
}

@mixin typography-sizes() {
  @include get-variants-responsive-deep($typography-settings);
}

@mixin heading-styles() {
  @each $heading in $heading-tags {
    #{$heading},
    .#{$heading} {
      @extend %headline;
    }
  }
}

@mixin typography-classes() {
  .text-desc {
    @include css-map($desc-text);
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: $default-text-color;

    &[disabled],
    &.is-disabled {
      pointer-events: none;
    }
  }

  .link,
  a:not([class]) {
    @include css-map($link, "default");

    cursor: pointer;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    hyphens: auto;

    &:focus,
    &:hover,
    &.is-active {
      @include css-map($link, "focus");
    }
  }

  address {
    font-style: normal;
  }

  abbr {
    cursor: help;
  }
}

@mixin list-unstyled() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin list-classes() {
  /* stylelint-disable no-descending-specificity */
  ul,
  ol,
  dl {
    margin: 0 0 $content-spacing;

    ul,
    ol,
    dl {
      margin-bottom: 0;
    }
  }

  .list--unstyled {
    @include list-unstyled;
  }

  .list--no-offset {
    padding-left: _get-space();
    list-style-position: outside;
  }

  .list--expanded {
    li {
      margin-bottom: _get-space("small");
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .list-inline--line {
    margin: 0;
    display: inline-block;

    > li {
      @include css-map($inline-list, "line");

      vertical-align: middle;
    }
  }

  .list-inline--expanded {
    > li {
      @include css-map($inline-list-item, "expanded");
    }
  }

  dl {
    dt {
      font-weight: bold;
    }

    dd {
      margin-left: 0;
      margin-bottom: $content-spacing;

      &:last-child {
        margin-bottom: 0;
      }
    }
  } // definition list

  .dl-inline {
    @include clearfix;

    dt,
    dd {
      float: left;
      margin: 0;
    }

    dt {
      clear: both;
    }

    dd {
      margin-left: _get-space("small");
    }
  }
  /* stylelint-enable no-descending-specificity */
}
