// ---------------------------------------------
// FORMS - GLOBALS - STYLES
// ---------------------------------------------

// -----------------
// form globals

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// -----------------
// form fieldset

fieldset {
  @include clearfix;

  margin: 0;
  padding: 0;
  border: 0;

  &.bottom-sep {
    margin-bottom: 15px;
    border-bottom: 1px solid #c2c8cd;
  }
}
