// ---------------------------------------------
// MODALS - MIXINS
// ---------------------------------------------

@import "./../../../styles/helpers/main";
@import "./config";

@mixin modal-overlay($settings: $modal-overlay) {
  @include css-map($settings);

  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $modal-overlay-z-index;
  opacity: 0;
  transform: scale(1.1, 1.1);
  background-color: $modal-overlay-bg-color;
  transition: opacity $modal-overlay-e, transform $modal-overlay-e;
  backface-visibility: hidden;

  .modal.is-active & {
    width: 100% !important;
    height: 100% !important;
    opacity: 1;
    transform: scale(1, 1);
    transition-delay: 0s;
  }
}

@mixin modal-dialog(
  $settings: $modal-dialog,
  $variants: $modal-dialog-variants
) {
  @include get-variants($variants);

  display: flex;
  flex-flow: column;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @each $breakpoint, $props in $settings {
    @include breakpoint($breakpoint) {
      $radius: map-get($props, "border-radius");

      @include css-map($settings, $breakpoint);

      > * + * {
        border-top: map-get($props, "border");
      }
    }
  }
}

@mixin modal-dialog-section(
  $settings,
  $spacing: $modal-dialog-section-spacing
) {
  @include css-map($settings);

  @include get-variants-responsive($spacing);

  width: 100%;
  flex: 0 0 auto;

  > *:last-child {
    margin-bottom: 0;
  }
}

@mixin modal-classes {
  .modal-dialog__overlay {
    @include modal-overlay;
  }

  .modal-dialog {
    @include modal-dialog;
  }

  .modal-dialog__header {
    @include modal-dialog-section($settings: $modal-dialog-header);
  }

  .modal-dialog__section {
    @include modal-dialog-section($settings: $modal-dialog-section);
  }

  .modal-dialog__footer {
    @include modal-dialog-section($settings: $modal-dialog-footer);
  }
}
