// ---------------------------------------------
// DROPDOWN - MIXINS
// ---------------------------------------------

@import "./../../../styles/helpers/main";
@import "./config";

@mixin dropdown-menu($dropdown-menu: $dropdown-menu) {
  @include css-map($dropdown-menu, "default");

  position: absolute;

  &:not(.is-active) {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    min-width: 0 !important;
    margin: -100%;
    pointer-events: none;

    * {
      display: none;
    }
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
    word-break: break-all;
  }

  &--space {
    @include css-map($dropdown-menu, "space");
  }

  &--nowrap.is-active {
    overflow: visible;
    max-height: none;
  }
}

@mixin dropdown-size() {
  max-height: $dropdown-menu-max-h;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

@mixin dropdown-item($dropdown-menu-item: $dropdown-menu-item) {
  display: block;
  width: 100%;
  border: 0;
  text-align: inherit;
  -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix

  &:focus,
  &:hover,
  &.is-active {
    @each $prop, $value in map-get($dropdown-menu-item, "focus") {
      #{$prop}: #{$value};
    }
  }

  @each $prop, $value in map-get($dropdown-menu-item, "default") {
    #{$prop}: #{$value};
  }
}

@mixin dropdown() {
  .dropdown {
    @include dropdown-menu;
    @include dropdown-size;

    &__item {
      @include dropdown-item;
    }
  }
}
