// ---------------------------------------------
// DROPDOWN - CONFIGURATION
// ---------------------------------------------

@import "./../../../styles/base/config";

$dropdown-menu-b-r: $default-border-radius;
$dropdown-menu-max-h: 300px;

$dropdown-menu: (
  default: (
    max-width: _get-rem(300px),
    margin-top: -1px,
    background-color: $color-white,
    border-width: 1px,
    border-style: solid,
    border-color: _color("grey", 300),
    border-radius: 0 0 $dropdown-menu-b-r,
    box-shadow: #{$shadow-direct-1,
    $shadow-ambient-1},
    z-index: 100,
    transition: #{visibility $ease-100-delayed,
    opacity $ease-out-100},
  ),
  space: (
    padding: _get-space(),
  ),
);

$dropdown-menu-item: (
  default: (
    padding: 10px,
    color: $color-black,
    transition: #{color $ease-out-200,
    background $ease-out-200},
  ),
  focus: (
    color: _color("primary", 500),
    background-color: _color("grey", 100),
  ),
  highlighted: (
    background-color: _color("grey", 100),
    color: _color("primary", 500),
  ),
);
