// ---------------------------------------------
// GRID - CONFIGURATION
// ---------------------------------------------

// wrappers
$container-widths: (
  xs: 960px,
  xl: 1080px,
); // You only need to set those breakpoints, where a change should occur.

$container-paddings: (
  xs: _get-space(),
  xl: _get-space("large"),
); // You only need to set those breakpoints, where a change should occur.

// grid
$grid-base: 12 !default;

$gutters: (
  xs: _get-space("small"),
  m: _get-space(),
  xl: _get-space("large"),
);

$gutters-form: (
  default: _get-space("small"),
);
