@import "./config";

.tooltip {
  z-index: $z-index-tooltip;
  position: absolute;

  &[aria-hidden="true"] {
    visibility: hidden;
  }

  &__inner {
    display: block;
    transition: transform $ease-out-200, opacity $ease-out-200;
    transform: translate3d(0, 0, 0);
    opacity: 1;

    [aria-hidden="true"] & {
      opacity: 0;
    }
  }

  &__content {
    @include css-map($tooltip-content);

    display: block;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__arrow {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    border-width: $tooltip-arrow-size;
    border-color: transparent;

    @each $direction, $opposite in $direction-opposites {
      [data-popper-placement^="#{$direction}"] & {
        border-#{$opposite}-width: 0;
        border-#{$direction}-color: $tooltip-arrow-color;
        #{$opposite}: -#{$tooltip-arrow-size};

        // stylelint-disable-next-line max-nesting-depth
        @if $direction == "top" or $direction == "bottom" {
          left: calc(50% - #{$tooltip-arrow-size});
          margin-top: 0;
          margin-bottom: 0;
        }

        // stylelint-disable-next-line max-nesting-depth
        @if $direction == "left" or $direction == "right" {
          top: calc(50% - #{$tooltip-arrow-size});
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  @each $direction, $opposite in $direction-opposites {
    &[data-popper-placement^="#{$direction}"] {
      margin-#{$opposite}: $tooltip-arrow-size * 2;
    }
  }
}
