// ---------------------------------------------
// SHAME CSS
// space for hacks and fixes
// ---------------------------------------------

// ---------------------------------------------
// Fix flex items text wrapping in ie10

.list__item > * {
  flex-shrink: 1;
}

// ---------------------------------------------
// Fix not working click events in IE11

use {
  pointer-events: none;
}
