// ---------------------------------------------
// GLOBALS
// ---------------------------------------------

@import "./config";

html,
body {
  padding: 0;
  margin: 0;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  position: relative;
  background: $default-bg-color;
  font-family: $default-font-family;
  color: $default-text-color;
}

* {
  box-sizing: border-box;
}

*::after {
  box-sizing: border-box;
}

*::before {
  box-sizing: border-box;
}

::selection {
  background-color: fade-out($selection-bg-color, 0.1);
  color: $selection-text-color;
}

// stylelint-disable-next-line selector-no-vendor-prefix
::-moz-selection {
  background-color: fade-out($selection-bg-color, 0.1);
  color: $selection-text-color;
}

a[href],
area[href],
input:not([disabled]),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
[tabindex],
[contentEditable="true"] {
  &:focus {
    outline: $default-outline;
  }
}

// override UA stylesheet, only when polyfill is loaded
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

// establish desired focus ring appearance for appropriate input modalities
.js-focus-visible .focus-visible {
  outline: $default-outline;
}

// stylelint-disable-next-line no-descending-specificity
button,
[role="button"] {
  border: 0 solid transparent;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix

  /* Remove excess padding and border in Firefox 4+ */
  // stylelint-disable-next-line no-descending-specificity
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@import "./globals-forms";
