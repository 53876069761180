// ---------------------------------------------
// LIST - MIXINS
// ---------------------------------------------

@import "../../Bar/styles/mixins";
@import "../../Typography/styles/mixins"; // in this file base list styles and classes are generated (inlude Typography mixins is not enough)
@import "./config";

@mixin list-base() {
  @include list-unstyled;

  display: flex;
  flex-flow: column;
  margin-bottom: $content-spacing;
}

@mixin list-style($variant: "default") {
  @include css-map($list, $variant);

  .list__item {
    @include css-map($list-item, $variant);

    @if css-map($list-item, $variant, "border-bottom") {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

@mixin list-item-clickable($list-item-clickable: $list-item-clickable) {
  @include css-map($list-item-clickable, "default");

  cursor: pointer;

  &:focus,
  &:hover {
    @include css-map($list-item-clickable, "focus");
  }

  &.is-selected {
    @include css-map($list-item-clickable, "selected");

    &:focus,
    &:hover {
      @include css-map($list-item-clickable, "selected-focus");
    }
  }

  &.is-disabled {
    @include css-map($list-item-clickable, "disabled");

    cursor: not-allowed;
  }
}

@mixin list-classes {
  .list {
    @include list-base;

    // .card__content > & {
    //   border-radius: map-get($card, 'border-radius');
    // }
  }

  .list__item--clickable {
    @include list-item-clickable;
  }

  @each $variant in $list-variants {
    .list#{_get-variant-name($variant)} {
      @include list-style($variant);
    }
  }
}
