// ---------------------------------------------
// MIXINS AND FUNCTIONS - BORDER
// ---------------------------------------------

// -------------------------------
// MIXINS

@mixin border-radius($radius, $border: "", $clip: true) {
  $moz-border: "";

  @if $border == "top left" {
    $border: "-top-left";
  } @else if $border == "top right" {
    $border: "-top-right";
  } @else if $border == "bottom left" {
    $border: "-bottom-left";
  } @else if $border == "bottom right" {
    $border: "-bottom-right";
  }

  border#{unquote($border)}-radius: $radius;

  @if $clip == true {
    //fix bg leaking outside the border
    background-clip: padding-box;
  }
}

@mixin border-top-radius($radius, $clip: true) {
  @include border-radius($radius, "top left", $clip);
  @include border-radius($radius, "top right", false);
}

@mixin border-right-radius($radius, $clip: true) {
  @include border-radius($radius, "top right", $clip);
  @include border-radius($radius, "bottom right", false);
}

@mixin border-bottom-radius($radius, $clip: true) {
  @include border-radius($radius, "bottom left", $clip);
  @include border-radius($radius, "bottom right", false);
}

@mixin border-left-radius($radius, $clip: true) {
  @include border-radius($radius, "top left", $clip);
  @include border-radius($radius, "bottom left", false);
}
