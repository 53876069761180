// ---------------------------------------------
// MIXINS AND FUNCTIONS - LAYOUT
// ---------------------------------------------

// -------------------------------
// MIXINS

// -- Nicolas Gallagher's micro clearfix
@mixin clearfix {
  *zoom: 1; // For IE 6/7 (trigger hasLayout)
  &::before,
  &::after {
    // For modern browsers
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin center-unknown($selector) {
  text-align: center;

  &::before {
    content: "";
    height: 100%;
    margin-right: -0.5em;
    display: inline-block;
    vertical-align: middle;
  }

  #{$selector} {
    display: inline-block;
    vertical-align: middle;
  }
}

// -------------------------------
// FUNCTIONS

@function _get-em($size, $font-size: $font-size-default) {
  @return $size / $font-size * 1em;
}

@function _get-rem($size, $base: $font-size-default) {
  @return $size / $base * 1rem;
}

/// Get space size from $spaces
/// @param {String} $size ['default'] - Size of space
/// @example
///   _get-space();
///   _get-space('small');
@function _get-space($size: "default") {
  @if map-has-key($spaces, $size) {
    $space: map-get($spaces, $size);

    @return if(unit($space) == "rem", $space, _get-rem($space));
  } @else {
    @error 'Cannot find key ($size) \'#{$size}\' in $spaces \'#{$spaces}\'';
  }
}
