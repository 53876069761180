// ---------------------------------------------
// LAYOUT UTILITIES
// ---------------------------------------------

@import "./../../styles/base/config";
@import "./../../styles/helpers/main";

// mixin for utilities with layout targeting
@mixin spacing-utilities() {
  $directions: (
    "top": "t",
    "right": "r",
    "bottom": "b",
    "left": "l",
  );

  @each $spaceName, $spaceSize in $spaces {
    .mb#{_get-variant-name($spaceName, "-")} {
      margin-bottom: $spaceSize !important;
    }
  }

  @each $directionName, $directionSelector in $directions {
    .m#{_get-variant-name($directionSelector, "")}-none {
      margin-#{$directionName}: 0 !important;
    }

    .p#{_get-variant-name($directionSelector, "")}-none {
      padding-#{$directionName}: 0 !important;
    }
  }

  .no-mrg {
    margin: 0 !important;
  }

  .no-pad {
    padding: 0 !important;
  }
}

@include spacing-utilities;

.clearfix {
  @include clearfix;
}
.clear {
  clear: both;
}

// Flexbox alignment classes
@mixin alignment-classes() {
  // Children horizontal alignment
  .align-items-right {
    justify-content: flex-end;
  }
  .align-items-center {
    justify-content: center;
  }
  .align-items-spaced {
    justify-content: space-around;
  }
  .align-items-justify {
    justify-content: space-between;
  }
  // Children vertcial alignment
  .align-items-top {
    align-items: flex-start;
  }
  .align-items-bottom {
    align-items: flex-end;
  }
  .align-items-middle {
    align-items: center;
  }

  // Selft horizontal alignmnet
  .align-self-left {
    margin-left: 0;
    margin-right: auto;
  }

  .align-self-right {
    margin-left: auto;
    margin-right: 0;
  }

  .align-self-center {
    margin-left: auto;
    margin-right: auto;
  }

  // Self vertical alignmnet
  .align-self-top {
    align-self: flex-start;
  }
  .align-self-bottom {
    align-self: flex-end;
  }
  .align-self-middle {
    align-self: center;
  }
  .align-self-stretch {
    align-self: stretch;
  }
}

@include alignment-classes;
