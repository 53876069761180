// ---------------------------------------------
// CONFIGURATION
// ---------------------------------------------

@import "./../helpers/main";
@import "./colors";

// CONTENTS
// breakpoints
// typography
// spacing
// colors
// selection
// utility
// general
// z-index
// shadows
// easings

// ----------------------------
// BREAKPOINTS

$breakpoints: (
  xs: 0,
  s: 480px,
  m: 640px,
  l: 960px,
  xl: 1280px,
);

// ----------------------------
// TYPOGRAPHY

$font-family-helvetica: "Helvetica Neue", helvetica, arial, sans-serif !default;
$font-family-opensans: "Open Sans", helvetica, arial, sans-serif !default;

$default-font-family: $font-family-opensans !default;
$font-size-default: 16px !default;
$font-size-large: 18px;
$font-size-small: 14px;

// ----------------------------
// SPACING

$spaces: (
  default: 16px,
  small: 8px,
  large: 24px,
);

// ----------------------------
// COLORS

$color-white: #fff !default;
$color-black: #000 !default;

$color-semiwhite: rgba(255, 255, 255, 0.5) !default;
$color-semiblack: rgba(0, 0, 0, 0.5) !default;

$color-palette: $color-palette;

$default-color-weights: (50, 100, 200, 300, 400, 500, 600, 700, 800, 900);

//---------------
// SELECTION

$selection-bg-color: _color("primary", 500);
$selection-text-color: $color-white;

//---------------
// utility

$text-color-primary: _color("primary");
$text-color-secondary: _color("secondary");
$text-color-black: $color-black;
$text-color-grey: _color("grey");
$text-color-white: $color-white;
$text-color-success: _color("success");
$text-color-error: _color("error");

// ----------------------------
// GENERAL

$border-radius: 3px !default;
$content-spacing: _get-space() !default;

$default-bg-color: $color-white !default;
$default-text-color: _color("grey", 900) !default;

$default-border-color: _color("grey", 200);
$default-border-radius: $border-radius !default;

$default-outline: 3px solid rgba(_color("primary", 200), 0.8);

// ----------------------------
// Z-INDEX

$z-index-default: 100 !default;
$z-index-topbar: 200 !default;
$z-index-overlay: 500 !default;
$z-index-tooltip: 600 !default;

// ----------------------------
// SHADOWS

$shadow-ambient-0: 0 2px 4px 0 rgba(0, 0, 0, 0.02) !default;
$shadow-ambient-1: 0 2px 4px 0 rgba(0, 0, 0, 0.07) !default;
$shadow-ambient-2: 0 3px 8px 0 rgba(0, 0, 0, 0.11) !default;
$shadow-ambient-3: 0 4px 13px 0 rgba(0, 0, 0, 0.15) !default;
$shadow-ambient-4: 0 15px 36px 0 rgba(0, 0, 0, 0.15) !default;
$shadow-ambient-5: 0 20px 43px 0 rgba(0, 0, 0, 0.17) !default;
$shadow-ambient-6: 0 30px 53px 0 rgba(0, 0, 0, 0.18) !default;

$shadow-direct-0: 0 1px 2px 0 rgba(0, 0, 0, 0) !default;
$shadow-direct-1: 0 1px 2px 0 rgba(0, 0, 0, 0.14) !default;
$shadow-direct-2: 0 3px 7px 0 rgba(0, 0, 0, 0.16) !default;
$shadow-direct-3: 0 5px 1px 0 rgba(0, 0, 0, 0.18) !default;
$shadow-direct-4: 0 8px 11px 0 rgba(0, 0, 0, 0.22) !default;
$shadow-direct-5: 0 12px 22px 0 rgba(0, 0, 0, 0.2) !default;
$shadow-direct-6: 0 20px 18px 0 rgba(0, 0, 0, 0.18) !default;

// ----------------------------
// EASINGS

$ease-50: 0.05s ease 0s !default;
$ease-100: 0.1s ease 0s !default;
$ease-out-100: 0.1s ease-out 0s !default;
$ease-200: 0.2s ease 0s !default;
$ease-out-200: 0.2s ease-out 0s !default;
$ease-300: 0.3s ease 0s !default;
$ease-400: 0.4s ease 0s !default;
$ease-200-delayed: 0.2s ease-out 0.2s !default;
$ease-100-delayed: 0.1s ease-out 0.1s !default;
$ease-600: 0.6s ease 0s !default;

$slow-end-anim: cubic-bezier(0.25, 0.25, 0, 0.995) !default;
