// ---------------------------------------------
// TYPOGRAPHY - STYLES
// ---------------------------------------------

@import "./mixins";

// SIZES
@include typography-sizes();

// HEADINGS
%headline {
  @include css-map($heading-base);
}

@include headings();
@include heading-styles();

// TEXT
@include typography-classes();

blockquote {
  @include css-map($blockquote);
}

cite {
  @include css-map($cite);

  display: block;
}

hr {
  @include css-map($hr);

  border: 0;
  height: 1px;
  display: block;
  margin-bottom: $content-spacing;
}

// LISTS
@include list-classes;
